import $ from 'jquery'

function setCustomStockExchangeYesClickHandler(pageId) {
  $(`${pageId} #custom-stock-exchange-yes`).click((e) => {
    if ($(`${pageId} #custom-stock-exchange-yes`).is(':checked')) {
      $(`${pageId} #custom-stock-exchange-wrapper`).append($customStockExchange);
      $(`${pageId} #custom-stock-exchange`).removeClass('hidden')
    }
  })
}

function setCustomStockExchangeNoClickHandler(pageId) {
  $(`${pageId} .custom-stock-exchange-no`).click((e) => {
    if (!$(`${pageId} #custom-stock-exchange-yes`).is(':checked') && $(`${pageId} #custom-stock-exchange`).length > 0) {
      $(`${pageId} #custom-stock-exchange`).addClass('hidden');
      $customStockExchange = $(`${pageId} #custom-stock-exchange`).remove();
    }
  })
}

$(function() {
  const pageId = '.js-reporting-issuer-group';
  let $customStockExchange;
  let $stockExchanges;

  if ($(`${pageId} #custom-stock-exchange-yes`).is(':checked')) {
    $(`${pageId} #custom-stock-exchange`).removeClass('hidden')
  } else {
    $customStockExchange = $(`${pageId} #custom-stock-exchange`).remove();
  }

  setCustomStockExchangeYesClickHandler(pageId);
  setCustomStockExchangeNoClickHandler(pageId);

  if ($(`${pageId} #is-reporting-issuer-yes`).is(':checked')) {
    $(`${pageId} #stock-exchanges`).removeClass('hidden')
  } else {
    $stockExchanges = $(`${pageId} #stock-exchanges`).remove();
  }

  $(`${pageId} #is-reporting-issuer-yes`).click((e) => {
    if ($(`${pageId} #is-reporting-issuer-yes`).is(':checked')) {
      $(`${pageId} #stock-exchanges-wrapper`).append($stockExchanges);
      $(`${pageId} #stock-exchanges`).removeClass('hidden');
      setCustomStockExchangeYesClickHandler(pageId);
      setCustomStockExchangeNoClickHandler(pageId);
    }
  })

  $(`${pageId} #is-reporting-issuer-no`).click((e) => {
    if ($(`${pageId} #is-reporting-issuer-no`).is(':checked') && $(`${pageId} #stock-exchanges`).length > 0) {
      $(`${pageId} #stock-exchanges`).addClass('hidden')
      $stockExchanges = $(`${pageId} #stock-exchanges`).remove();
    }
  })
})
